<template>
  <form class="form-register text-left" id="form-register" action="javascript:void(0);">
    <div>
      <div class="steps clearfix">
        <ul role="tablist">
          <li role="tab" aria-disabled="false" class="first current" aria-selected="true">
            <a id="form-total-t-0" aria-controls="form-total-p-0" style="cursor: pointer">
              <span class="current-info audible"></span>
              <div class="title" @click="nextSetp(1, 1)">
                <p class="step-icon" :style="{ background: tab == 1 ? '#2ed3ae' : '#ccc' }">
                  <span>1</span>
                </p>
                <span class="step-text">{{ $t('lang.form.step_4') }}</span>
              </div>
            </a>
          </li>
          <li role="tab" aria-disabled="false">
            <a id="form-total-t-1" aria-controls="form-total-p-1" style="cursor: pointer">
              <div class="title" @click="nextSetp(2, 2)">
                <p class="step-icon" :style="{ background: tab == 2 ? '#2ed3ae' : '#ccc' }">
                  <span>2</span>
                </p>
                <span class="step-text">{{ $t('lang.form.step_5') }}</span>
              </div>
            </a>
          </li>
          <li role="tab" aria-disabled="false" class="last">
            <a id="form-total-t-2" aria-controls="form-total-p-2" style="cursor: pointer">
              <div class="title" @click="nextSetp(3, 3)">
                <p class="step-icon" :style="{ background: tab == 3 ? '#2ed3ae' : '#ccc' }">
                  <span>3</span>
                </p>
                <span class="step-text">{{ $t('lang.form.step_6') }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div :style="{ display: tab == 1 ? 'block' : 'none' }" id="tab-1-base" style="position: relative; z-index: 1">
        <section class="content clearfix">
          <div class="inner rd-formstep" style="margin-top: 25px">
            <div class="form-heading">
              <h3>{{ $t('lang.form.subtitle_4') }}</h3>
              <span>1/3</span>
            </div>

            <v-form ref="form1" v-model="valid" lazy-validation class="dialog-form">
              <v-container class="no-padding">
                <v-row>
                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.name')"
                      v-model="editedItem.name"
                      :autocomplete="'off'"
                      :rules="nameRules"
                      color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.last_name')"
                      v-model="editedItem.lastName"
                      :autocomplete="'off'"
                      :rules="lastNameRules"
                      color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.email')"
                      v-model="editedItem.email"
                      :autocomplete="'off'"
                      :rules="emailRules"
                      color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.phone')"
                      v-model="editedItem.phone"
                      :autocomplete="'off'"
                      :rules="phoneRules"
                      color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.linkedIn')"
                      v-model="editedItem.linkedin"
                      :autocomplete="'off'"
                      :rules="linkedinRules"
                      color="white"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.province"
                      :items="listProvince"
                      :label="$t('lang.form.form.province')"
                      item-text="name"
                      item-value="id"
                      return-object
                      @change="changeSelect('provinces')"
                      color="white"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.city"
                      :items="listCity"
                      :label="labelCity"
                      item-text="name"
                      item-value="id"
                      return-object
                      :disabled="disabledCity"
                      color="white"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.availability"
                      :items="listAvailability"
                      :label="$t('lang.form.form.availability')"
                      item-text="name"
                      item-value="id"
                      return-object
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.profiles"
                      :items="listProfile"
                      :label="$t('lang.form.form.profile')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      @change="selectProfile()"
                      @click="selectProfile()"
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.professionalLevel"
                      :items="listProfessionalLevel"
                      :label="$t('lang.form.form.professional_levels')"
                      item-text="name"
                      item-value="id"
                      return-object
                      :rules="levelRules"
                      color="white"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>

          <div class="actions clearfix" style="margin-top: 50px; text-align: center">
            <button class="button button-jerry button-primary btn-size" @click="next(1, 2)">
              {{ $t('lang.form.btn.next') }}
              <span class="button-jerry-line"></span>
            </button>
          </div>
        </section>
      </div>

      <div :style="{ display: tab == 2 ? 'block' : 'none' }" id="tab-2-base" style="position: relative; z-index: 1">
        <section class="content clearfix">
          <div class="inner margen-tab-top rd-formstep-2">
            <div class="form-heading">
              <h3>{{ $t('lang.form.subtitle_5') }}</h3>
              <span>2/3</span>
            </div>

            <v-form ref="form2" v-model="valid2" lazy-validation class="dialog-form">
              <v-container class="no-padding">
                <v-row>
                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.technologies"
                      :items="listTechnologies"
                      :label="$t('lang.form.form.technologies_skills_1')"
                      item-text="name"
                      item-value="id"
                      multiple
                      return-object
                      :rules="technologiesRules"
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.methodologies"
                      :items="listMethodologies"
                      :label="$t('lang.form.form.methodologies')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.certifications"
                      :items="listCertifications"
                      :label="$t('lang.form.form.certifications')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-text-field
                      :label="$t('lang.form.form.year')"
                      v-model="editedItem.yearsExperience"
                      type="number"
                      :autocomplete="'off'"
                      counter="2"
                      min="0"
                      color="white"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.mode"
                      :items="listMode"
                      :label="$t('lang.form.form.modality_2')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.workingDay"
                      :items="listWorkingDay"
                      :label="$t('lang.form.form.working_day_2')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.typeContract"
                      :items="listContract"
                      :label="$t('lang.form.form.contract')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.paymentType"
                      :items="listPaymentType"
                      :label="$t('lang.form.form.salary')"
                      item-text="name"
                      item-value="id"
                      return-object
                      color="white"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="text-no-padding"
                    v-if="editedItem.paymentType && editedItem.paymentType.id == 2"
                  >
                    <v-text-field
                      :label="$t('lang.form.form.since')"
                      v-model="editedItem.payFrom"
                      type="number"
                      :autocomplete="'off'"
                      counter="10"
                      min="0"
                      color="white"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="text-no-padding"
                    v-if="editedItem.paymentType && editedItem.paymentType.id == 2"
                  >
                    <v-text-field
                      :label="$t('lang.form.form.until')"
                      v-model="editedItem.payTo"
                      type="number"
                      :autocomplete="'off'"
                      counter="10"
                      min="0"
                      color="white"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding" style="margin-bottom: 15px">
                    <v-checkbox
                      v-model="editedItem.autonomo"
                      :label="$t('lang.form.form.autonomo')"
                      hide-details
                      color="navDark"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>

            <div class="actions clearfix" style="margin-top: 50px; text-align: center">
              <button class="button button-jerry button-primary btn-size" style="margin-right: 5px" @click="next(2, 1)">
                {{ $t('lang.form.btn.prev') }}
                <span class="button-jerry-line"></span>
              </button>

              <button class="button button-jerry button-primary btn-size" style="margin-left: 5px" @click="next(2, 3)">
                {{ $t('lang.form.btn.next') }}
                <span class="button-jerry-line"></span>
              </button>
            </div>
          </div>
        </section>
      </div>

      <div :style="{ display: tab == 3 ? 'block' : 'none' }" id="tab-3-base" style="position: relative; z-index: 1">
        <section class="content clearfix">
          <div class="inner margen-tab-top rd-formstep-3">
            <div class="form-heading">
              <h3>{{ $t('lang.form.subtitle_6') }}</h3>
              <span>3/3</span>
            </div>

            <v-form ref="form3" v-model="valid3" lazy-validation class="dialog-form">
              <v-container class="no-padding">
                <v-row>
                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-autocomplete
                      v-model="editedItem.alert"
                      :items="listAlert"
                      :label="$t('lang.form.form.alert')"
                      item-text="name"
                      item-value="id"
                      return-object
                      multiple
                      color="white"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="text-no-padding">
                    <v-textarea
                      name="comments"
                      :label="$t('lang.form.form.comments')"
                      v-model="editedItem.comments"
                      :autocomplete="'off'"
                      rows="5"
                      counter="500"
                      color="white"
                    ></v-textarea>
                  </v-col>
                  <div class="form-wrap form-wrap-top font-privacy mt-2">
                    <label class="container-check ml-5 pt-1">
                      <div v-html="$t('lang.form.message.recaptcha')"></div>
                      <input type="checkbox" id="contact-check" value="true" v-model="checkedPolicy" />
                      <div id="errorCheck3"></div>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <v-col cols="12" md="12" class="text-no-padding">
                    <div class="form-wrap" id="captchaStepBase">
                      <!-- <span id="captcha-step" class="help-block with-errors">Por favor complete el Captcha</span> -->
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>

            <div class="actions clearfix" style="margin-top: 50px; text-align: center">
              <button class="button button-jerry button-primary btn-size" style="margin-right: 5px" @click="next(3, 2)">
                {{ $t('lang.form.btn.prev') }}
                <span class="button-jerry-line"></span>
              </button>

              <button class="button button-jerry button-primary btn-size" style="margin-left: 5px" @click="save()">
                {{ $t('lang.form.btn.send') }}
                <span class="button-jerry-line"></span>
              </button>
            </div>
          </div>
        </section>
        <FlashMessage :position="'right top'"></FlashMessage>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { Services } from '../../services/index';
export default Vue.extend({
  name: 'FormProfessional',
  data: () => ({
    valid: false,
    valid2: false,
    valid3: false,
    editedItem: {
      selfRegistered: true,
      selfEmployee: true,
      profile: [],
      alert: [],
      technologiesRules: [],
      typeContract: []
    },
    listCountry: [],
    listProvince: [],
    listCity: [],
    listTechnologies: [],
    listMethodologies: [],
    listCertifications: [],
    listProfile: [],
    disabledProvince: true,
    disabledCity: true,
    labelCity: '',
    tab: 1,
    setp1: false,
    setp2: false,
    setp3: false,
    arraySkills: [],
    listContract: [],
    listWorkingDay: [],
    listMode: [],
    listPaymentType: [],
    listAvailability: [],
    listProfessionalLevel: [],
    listAlert: [],
    nameRules: [],
    lastNameRules: [],
    linkedinRules: [],
    emailRules: [],
    phoneRules: [],
    levelRules: [],
    offerRules: [],
    technologiesRules: [],
    checkedPolicy: false,

    datePublication: new Date(),
    numberVacancies: 1
  }),
  mounted() {
    this.listProvince = JSON.parse(localStorage.getItem('provinces'));
    this.listTechnologies = JSON.parse(localStorage.getItem('technologies'));
    this.listMethodologies = JSON.parse(localStorage.getItem('methodologies'));
    this.listCertifications = JSON.parse(localStorage.getItem('certifications'));
    this.listProfile = localStorage.getItem('profiles') ? JSON.parse(localStorage.getItem('profiles')) : [];

    this.labelCity = this.$t('lang.form.form.city');
    this.listContract = [
      { id: 1, name: this.$t('lang.form.select.listContract.option_1') },
      { id: 2, name: this.$t('lang.form.select.listContract.option_2') },
      { id: 3, name: this.$t('lang.form.select.listContract.option_3') }
    ];
    this.listWorkingDay = [
      { id: 1, name: this.$t('lang.form.select.listWorkingDay.option_1') },
      { id: 2, name: this.$t('lang.form.select.listWorkingDay.option_2') }
    ];
    this.listMode = [
      { id: 1, name: this.$t('lang.form.select.listMode.option_1') },
      { id: 2, name: this.$t('lang.form.select.listMode.option_2') },
      { id: 3, name: this.$t('lang.form.select.listMode.option_3') },
      { id: 4, name: this.$t('lang.form.select.listMode.option_4') }
    ];
    this.listPaymentType = [
      { id: 1, name: this.$t('lang.form.select.listPaymentType.option_1') },
      { id: 2, name: this.$t('lang.form.select.listPaymentType.option_2') }
    ];
    this.listAvailability = [
      { id: 1, name: this.$t('lang.form.select.listAvailability.option_1') },
      { id: 2, name: this.$t('lang.form.select.listAvailability.option_2') },
      { id: 3, name: this.$t('lang.form.select.listAvailability.option_3') }
    ];
    this.listProfessionalLevel = [
      { id: 1, name: this.$t('lang.form.select.listProfessionalLevel.option_1') },
      { id: 2, name: this.$t('lang.form.select.listProfessionalLevel.option_2') },
      { id: 3, name: this.$t('lang.form.select.listProfessionalLevel.option_3') }
    ];
    this.listAlert = [
      { id: 1, name: this.$t('lang.form.select.listAlert.option_1') },
      { id: 2, name: this.$t('lang.form.select.listAlert.option_2') },
      { id: 3, name: this.$t('lang.form.select.listAlert.option_3') },
      { id: 4, name: this.$t('lang.form.select.listAlert.option_4') }
    ];

    this.nameRules = [(v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.name') })];
    this.lastNameRules = [
      (v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.last_name') })
    ];
    this.linkedinRules = [
      (v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.linkedIn') }),
      (v) => !v || /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/.test(v) || this.$t('lang.form.error.linkedIn_valid')
    ];
    this.emailRules = [
      (v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.email') }),
      (v) =>
        !v ||
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(v) ||
        this.$t('lang.form.error.email_valid')
    ];
    this.phoneRules = [
      (v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.phone') }),
      (v) => !v || /^(\+\d)?[0-9\-() ]{5,}$/.test(v) || this.$t('lang.form.error.phone_valid')
    ];
    this.technologiesRules = [
      (v) => v.length !== 0 || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.technologies') })
    ];

    //TODO: check this rules:

    this.levelRules = [(v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.level') })];
    this.offerRules = [(v) => !!v || this.$t('lang.form.error.required', { name: this.$t('lang.form.error.offer') })];
  },
  methods: {
    selectProfile() {
      const validate = (value, codition1, condition2) => {
        let element = codition1;
        this.editedItem.profiles.map((item) => {
          if (item.name.indexOf(value) !== -1) {
            element = condition2;
          }
        });
        return element;
      };

      this.listProfile.map((item) => {
        if (this.editedItem.profiles.length < 3) {
          item.disabled = validate(item.name, false, false);
        }

        if (this.editedItem.profiles.length == 3) {
          item.disabled = validate(item.name, true, false);
        }
      });

      this.editedItem.profiles.map((element) => {
        element.disabled = false;
      });
    },
    async getCity(provinceId) {
      this.labelCity = this.$t('lang.form.form.wait_city');
      Services.getCity(provinceId).then((resp) => {
        const response = resp.data;
        if (response) {
          if (response.code == 200) {
            this.listCity = response.data;
            this.disabledCity = false;
            this.labelCity = this.$t('lang.form.form.city');
          } else if (response.code == 404) {
            this.listCity = [];
          }
        } else {
          this.listCity = [];
        }
      });
    },
    changeSelect(value) {
      if (value == 'country') {
        this.getProvince(this.editedItem.country.code);
      } else if (value == 'provinces') {
        this.getCity(this.editedItem.province.id);
      }
    },
    next(value, tab) {
      switch (value) {
        case 1:
          var tab1 = this.$refs.form1.validate();
          if (tab1) {
            this.tab = tab;
            this.setp1 = true;
            window.location.href = '#professional-form';
          }
          this.$ga.event({
            eventCategory: 'form-buscando',
            eventAction: 'go-to-step-2',
            eventLabel: 'button-step-1'
          });
          break;
        case 2:
          var tab2 = this.$refs.form2.validate();
          if (tab2) {
            this.tab = tab;
            this.setp2 = true;
            window.location.href = '#professional-form';
          }
          this.$ga.event({
            eventCategory: 'form-buscando',
            eventAction: 'go-to-step-3',
            eventLabel: 'button-step-2'
          });
          break;
        case 3:
          this.tab = tab;
          this.setp3 = true;
          window.location.href = '#professional-form';
          break;
      }
    },
    nextSetp(value, tab) {
      switch (value) {
        case 1:
          if (this.setp1) {
            this.tab = tab;
          }
          break;
        case 2:
          if (this.setp2) {
            this.tab = tab;
          }
          break;
        case 3:
          if (this.setp3) {
            this.tab = tab;
          }
          break;
      }
    },
    errorMessage: function() {
      this.flashMessage.error({
        status: 'error',
        title: this.$t('lang.form.alert.error'),
        message: this.$t('lang.form.alert.message2'),
        icon: '/img/icons/error.svg',
        clickable: false,
        time: 5000
      });
    },

    successMessage: function() {
      this.flashMessage.success({
        status: 'success',
        title: this.$t('lang.form.alert.success'),
        message: this.$t('lang.form.alert.message1'),
        icon: '/img/icons/success.svg',
        clickable: false,
        time: 5000
      });
    },
    submitForm() {
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/professionals`, this.editedItem)
        .then((resp) => {
          const response = resp.data;
          if (response) {
            if (response.code == 201) {
              this.successMessage();
              setTimeout(() => {
                this.tab = 1;
                this.$refs.form1.reset();
                this.$refs.form2.reset();
                this.$refs.form3.reset();
                this.setp1 = false;
                this.setp2 = false;
                this.setp3 = false;
                window.location.href = '#professional-form';
              }, 7000);
            } else if (response.code == 400 || resp.status == 400) {
              console.log('error 400', resp);
              this.errorMessage();
            } else if (response.code == 500 || resp.status == 500) {
              console.log('error 500', resp);
              this.errorMessage();
            }
          } else {
            console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', response);
            this.errorMessage();
          }
        })
        .catch((error) => {
          this.errorMessage();
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },
    async save() {
      this.$ga.event({
        eventCategory: 'form-buscando',
        eventAction: 'submit',
        eventLabel: 'button-step-3'
      });
      this.setp3 = true;
      const tab3 = this.$refs.form3.validate();
      this.editedItem.country = JSON.parse(localStorage.getItem('country'))[0];
      this.editedItem.linkedin = this.editedItem.linkedin.split('?')[0];
      if (this.editedItem.alert.length == 0) {
        this.editedItem.alert.push({
          id: 4,
          name: this.$t('lang.form.select.listAlert.option_4')
        });
      }

      if (this.checkedPolicy == false) {
        window
          .$('#errorCheck3')
          .append(
            "<span id='errorCheck2' class='help-block with-errors form-validation-color'>Debes aceptar las políticas de privacidad</span>"
          );
        window.$('.checkmark').css('border', 'solid red 2px');
      }

      const response = window.grecaptcha.getResponse(window.captchaStep);
      if (response.length == 0) {
        window.$('#captcha-step').fadeIn();
        window.$('iframe').css('border', 'solid red 1px');
      }
      console.log('window.grecaptcha', response);
      if (tab3) {
        if (response.length > 0 || process.env.NODE_ENV === 'development') {
          this.submitForm();
        }
      }
    }
  }
});
</script>
